import classNames from 'classnames';
import React from 'react';

import Heading from 'components/shared/heading';

import DataTransferIcon from './images/data-transfer.inline.svg';
import PrivacyDesignIcon from './images/privacy-design.inline.svg';
import PrivacyPolicyIcon from './images/privacy-policy.inline.svg';

const iconCollection = {
  dataTransfer: DataTransferIcon,
  privacyDesign: PrivacyDesignIcon,
  privacyPolicy: PrivacyPolicyIcon,
};

const itemClassNames = classNames(
  /* base */ 'flex flex-col justify-between bg-secondary-1 rounded-3xl p-5 flex-shrink-0 flex-grow-0 max-w-[219px]',
  /* xl-size */ 'xl:p-8 xl:pr-7 xl:pb-16 xl:rounded-4xl',
  /* lg-size */ 'lg:pb-9',
  /* md-size */ 'md:max-w-max'
);

const Item = ({ iconName, title, description }) => {
  const Icon = iconCollection[iconName];

  return (
    <div className={itemClassNames}>
      <div>
        <Icon className="w-[50px] h-[50px] xl:w-auto xl:h-auto" />
        <Heading
          className="mt-3.5 xl:mt-5 font-extrabold leading-6 xl:!leading-10"
          tag="h3"
          size="md"
          theme="white"
        >
          {title}
        </Heading>
        <p className="mt-3 text-sm leading-snug text-white xl:mt-4 xl:text-lg">{description}</p>
      </div>
    </div>
  );
};

export default Item;
