import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import IconCheck from './images/check.inline.svg';

const Scalability = ({ title, description, items }) => (
  <section className="mt-24 md:mt-28 lg:mt-40">
    <Container>
      <div className="grid grid-cols-12 xl:gap-x-10">
        <div className="relative col-start-1 col-end-13 lg:col-start-2 lg:col-end-12">
          <Heading
            className="lg:max-w-[445px] xl:max-w-[585px] 2xl:max-w-[688px]"
            tag="h2"
            size="lg"
            fontWeight="extra"
          >
            {title}
          </Heading>
          <p className="font-semibold text-xl xl:text-xxl mt-3 xl:mt-5">{description}</p>

          <ul className="grid md:grid-cols-2 gap-y-8 lg:gap-y-14 md:gap-x-8 mt-10 md:mt-14">
            {items.map(({ title, text }, index) => (
              <li className="flex" key={index}>
                <IconCheck className="flex-shrink-0 xl:mt-2" />
                <div className="flex flex-col ml-4 lg:ml-6">
                  <Heading className="!leading-tight" tag="h3" fontWeight="extra" size="md" asHTML>
                    {title}
                  </Heading>
                  <p className="text-base lg:text-lg xl:text-xl mt-1.5 xl:mt-4">{text}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  </section>
);

Scalability.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Scalability;
