import { graphql } from 'gatsby';
import React from 'react';

import Hero from 'components/pages/security/hero';
import Privacy from 'components/pages/security/privacy';
import Scalability from 'components/pages/security/scalability';
import BookADemo from 'components/shared/book-a-demo';
import MainLayout from 'layouts/main';

const Security = ({
  data: {
    wpPage: { seo, acf: data },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext}>
    <Hero {...data.securityHero} />
    <Scalability {...data.securityScalability} />
    <Privacy {...data.securityPrivacy} />
    <BookADemo />
  </MainLayout>
);

export default Security;

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      uri
      acf {
        securityHero {
          title
          description
        }
        securityScalability {
          title
          description
          items {
            title
            text
          }
        }
        securityPrivacy {
          title
          items {
            iconName
            title
            description
          }
        }
      }
      ...wpPageSeo
    }
  }
`;
