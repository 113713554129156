import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import LineBottomLeft from './images/line-bottom-left.inline.svg';
import LineBottomRight from './images/line-bottom-right.inline.svg';
import LineTopLeft from './images/line-top-left.inline.svg';
import LineTopRight from './images/line-top-right.inline.svg';

const Hero = ({ title, description }) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "pages/security/hero/illustration.png" }) {
        childImageSharp {
          gatsbyImageData(width: 652)
        }
      }
    }
  `);
  return (
    <section className="header-offset">
      <Container>
        <div className="relative bg-secondary-5 rounded-4xl xl:rounded-5xl p-8 sm:p-16 pb-14">
          <LineTopLeft
            className="hidden lg:block w-auto h-[50px] xl:h-[80px] 2xl:h-auto absolute top-0 left-0"
            aria-hidden
          />
          <LineTopRight
            className="hidden lg:block w-auto h-[70px] xl:h-[120px] 2xl:h-auto absolute top-0 right-0"
            aria-hidden
          />
          <div className="relative max-w-[970px] mx-auto">
            <Heading className="text-center uppercase tracking-wide" tag="h1" size="lg">
              {title}
            </Heading>
            <p className="mt-3.5 text-center font-semibold text-xl xl:text-xxl">{description}</p>
          </div>

          <div className="relative flex justify-center mt-10">
            <LineBottomLeft
              className="hidden lg:block absolute top-0 -left-16 top-1/2 transform -translate-y-1/2 mt-3 xl:mt-4 2xl:mt-6 w-auto lg:h-[130px] xl:h-[160px] 2xl:h-auto"
              aria-hidden
            />
            <LineBottomRight
              className="hidden lg:block absolute top-0 -right-16 top-1/2 transform -translate-y-1/2 -mt-1 w-auto lg:h-[130px] xl:h-[170px] 2xl:h-auto"
              aria-hidden
            />
            <GatsbyImage className="relative" image={getImage(illustration)} alt="AICPA|SOC 2" />
          </div>
          <div className="relative text-center mt-10">
            <span className="font-cursive text-xxl lg:text-2xl xl:text-[30px]">
              SOC 2 Type II + GDPR + CCPA
            </span>
          </div>
        </div>
      </Container>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Hero;
